import React from 'react';
import Nav from './components/nav/nav';
import DownloadCV from './components/downloadCV/DownloadCV';
import Header from './components/home/Header';
import About from './components/about/about';
import Projects from './components/projects/projects';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import './App.css';

function App() {

  return (
    <div className="App" >
      <Nav />
      <Header />
      <About />
      <Projects />
      <Contact />
      <Footer />
      <DownloadCV />
    </div>
  );
}

export default App;
