import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import './Header.css';

const bgImg = 'https://res.cloudinary.com/dnbq6nf5d/image/upload/v1630326612/Portfolio%20Assets/markus-spiske-FXFz-sW0uwo-unsplash2_i2q7xe.png';

const Header = () => {

    return (
        <div
            className="header"
        >
            <img className="bgImg" src={bgImg} alt='bg'/>
            <div id='home' className='header-overlay'>
                <div className='header-title-container' >
                    <p className='header-title-name' >Mihle Damoyi</p>
                    <p className='header-title-occupation' >Software Developer</p>
                    <div style={{ padding: '10px 0', width: 100, display: 'flex', alignSelf: 'center', justifyContent: 'space-around' }} >
                        <a href='https://www.linkedin.com/in/mihle-damoyi' target="_blank" rel='noreferrer'><FontAwesomeIcon icon={['fab', 'linkedin']} className='h-ic' size='lg' color='#3a4e48' /></a>
                        <a href="https://www.facebook.com/mihle.damoyi" target="_blank" rel='noreferrer'><FontAwesomeIcon icon={['fab', 'facebook']} className='h-ic' size='lg' color='#3a4e48' /></a>
                        <a href='https://github.com/GeminiWolf' target="_blank" rel='noreferrer'><FontAwesomeIcon icon={['fab', 'github']} className='h-ic' size='lg' color='#3a4e48' /></a>
                    </div>
                </div>

                <div className='header-fade' >
                    <a href='#about' className='header-downBtn' >
                        <FontAwesomeIcon
                            // onMouseEnter={()=>}
                            // onMouseLeave={()=>}
                            icon={faAngleDown}
                            size='2x'
                            color='#f5f5f5'
                        />
                    </a>
                </div>
            </div>
        </div >
    )
}

export default Header;