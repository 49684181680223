import React, { useState } from "react";
import { SiGithub } from "react-icons/si";
import { BiLink } from "react-icons/bi";
import "./projects.css";

const sites = [
	{
		id: "1",
		name: "moviestream",
		techs: ["React.JS", "NodeJS", "API"],
		description: "ManiacStream",
		imgUrl:
			"https://res.cloudinary.com/dnbq6nf5d/image/upload/v1630336635/Portfolio%20Assets/screencapture-maniacstream-herokuapp-2021-08-30-17_16_38_hk7cfl.png",
		site: "http://maniacstream.herokuapp.com",
		gitsite: "http://github.com/GeminiWolf/maniacstreams",
	},
	{
		id: "2",
		name: "React-Snake",
		techs: ["React.JS"],
		description: "Snake Game in React",
		imgUrl:
			"https://res.cloudinary.com/dnbq6nf5d/image/upload/v1632335010/Portfolio%20Assets/screencapture-flamboyant-knuth-61fed1-netlify-app-2021-09-22-20_22_51_1_vr1cmx.png",
		site: "https://flamboyant-knuth-61fed1.netlify.app/",
		gitsite: "https://github.com/GeminiWolf/snake-game",
	},
];

const Projects = () => {
	const [isShow, setShow] = useState("");
	const [webImgUrl, setWebImgUrl] = useState([]);

	const getScreenshots = () => {
		// let urlArr = [];

		sites.map((site) =>
			fetch(
				`https://shot.screenshotapi.net/screenshot?token=P7YJ0X6-2YCMHDY-P1BNQED-6CRF1BC&url=${site.site}`
			)
				.then((res) => res.json())
				.then((data) => {
					setWebImgUrl((current) => [...current, data.screenshot]);
					console.log(data.screenshot);
				})
				.catch()
		);

		// setWebImgUrl(urlArr);
	};

	if (!webImgUrl.length) getScreenshots();

	return (
		<div className="projects-container">
			<div id="projects" />
			<h2 className="projects-title">Projects</h2>
			<div className="projects-grid">
				{sites.map((site, i) => (
					<div key={site.id} className="project-card">
						<div
							className="project-card-img"
							onClick={() =>
								isShow !== site.name ? setShow(site.name) : setShow("")
							}
						>
							{webImgUrl[i] && (
								<img
									className="project-card-img-card"
									alt="pic"
									src={webImgUrl[i]}
								/>
							)}
						</div>
						<div className="project-card-description-container">
							{site.description} - {site.techs.toString()}
						</div>
						<div className={`project-card-btn-container show-links`}>
							<div className="project-card-btn">
								<a href={site.site} target="_blank" rel="noreferrer">
									<BiLink />
									Live
								</a>
							</div>
							<div className="project-card-btn">
								<a href={site.gitsite} target="_blank" rel="noreferrer">
									<SiGithub />
									Code
								</a>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Projects;
