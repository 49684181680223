import React from "react";
import "./about.css";
import {
	SiHtml5,
	SiJavascript,
	SiCss3,
	SiCplusplus,
	SiReact,
	SiMysql,
	SiMongodb,
	SiNodedotjs,
} from "react-icons/si";
import { FaJava } from "react-icons/fa";

const fZ = 80;

const tech = [
	{
		id: 1,
		name: "Html",
		langIcon: <SiHtml5 style={{ fontSize: fZ }} />,
	},
	{
		id: 2,
		name: "Javascript",
		langIcon: <SiJavascript style={{ fontSize: fZ }} />,
	},
	{
		id: 3,
		name: "Css",
		langIcon: <SiCss3 style={{ fontSize: fZ }} />,
	},
	{
		id: 5,
		name: "Reactjs",
		langIcon: <SiReact style={{ fontSize: fZ }} />,
	},
	{
		id: 7,
		name: "MySQL",
		langIcon: <SiMysql style={{ fontSize: fZ }} />,
	},
	{
		id: 8,
		name: "MongoDB",
		langIcon: <SiMongodb style={{ fontSize: fZ }} />,
	},
	{
		id: 9,
		name: "Nodejs",
		langIcon: <SiNodedotjs style={{ fontSize: fZ }} />,
	},
	{
		id: 9,
		name: "c++",
		langIcon: <SiCplusplus style={{ fontSize: fZ }} />,
	},
	{
		id: 6,
		name: "Java",
		langIcon: <FaJava style={{ fontSize: fZ }} />,
	},
];

const About = () => {
	return (
		<div className="about-container">
			<div id="about" />
			<div className="about-img">
				<div className="about-img-border">
					<img
						className="about-my-pic"
						alt="about-my-pic"
						src={
							"https://res.cloudinary.com/dnbq6nf5d/image/upload/v1658137040/Portfolio%20Assets/MihleBg_nctlpq.png"
						}
					/>
				</div>
			</div>
			<div className="about-me">
				<h1>ABOUT ME</h1>
				<div unselectable="off">
					I draw inspiration from different ideas and systems and intrigued by
					how they work. My love for learning new things which is forever
					growing, got me interested in wanting to develop different systems in
					this ever changing field.
				</div>
			</div>
			<div className="about-me-technologies-container">
				<div className="about-me-technologies-title">Technologies:</div>
				<div className="about-me-technologies-img-container">
					{tech.map((tech) => (
						<div key={`${tech.name}`} className="about-me-technology">
							{tech.langIcon}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default About;
