import React from "react";
import "./Downloadbtn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import cv from "../../assets/Mihle-Damoyi-FlowCV-Resume-20240723.pdf";

const DownloadCV = () => {
	return (
		<div className="Downloadbtn-container">
			<a href={cv} download="MihleDamoyiResume.pdf">
				<div className="Downloadbtn">
					<FontAwesomeIcon icon={faFileAlt} className="download-ic" />
				</div>
			</a>
		</div>
	);
};

export default DownloadCV;
