import React, { useState } from 'react';
import { Link } from "react-scroll";
import logow from '../../assets/WolfLogo_White.png'
import './nav.css';

export default function Nav() {
    const [isNavOpen, setNavOpen] = useState(false)
    const [isNavBackground, setNavBackground] = useState(false)

    onscroll = () => {
        if (window.scrollY > 50) {
            setNavBackground(true)
        }
        else {
            setNavBackground(false)
        }
    }

    const openNav = () => {
        if (!isNavOpen) {
            setNavOpen(true)
            document.documentElement.style.overflow = 'hidden'
            document.body.scroll = 'no'
        }
        else {
            setNavOpen(false)
            document.documentElement.style.overflow = 'scroll';
            document.body.scroll = "yes";
        }
    }

    return (
        <div className="nav">
            <div
                className={`nav-container ${!isNavBackground ? "" : "nav-container-background"}`}
                onScroll={onscroll}>
                <div className="nav-logo">
                    <img alt='logo' src={logow} style={{ height: 20, marginRight: 10 }} />
                    <h1 className='GmW'>Gemini Wolf</h1>
                </div>
                <div
                    className={`menu-btn ${!isNavBackground ? '' : "menu-btn-color"} ${isNavOpen && "toggle"}`}
                    onClick={openNav}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
                <div className={`nav-list-container ${!isNavBackground ? "" : "nav-list-color-change"} ${!isNavOpen ? "nav-show" : ""}`}>
                    <ul className="nav-list">
                        <Link
                            activeClass="active"
                            to="home"
                            spy={true}
                            duration={500}
                            className='nav-list-items'
                        >
                            Home
                        </Link>
                        <Link
                            activeClass="active"
                            to="about"
                            offset={-100}
                            spy={true}
                            duration={500}
                            className='nav-list-items'
                        >
                            About
                        </Link>
                        <Link
                            activeClass="active"
                            to="projects"
                            spy={true}
                            duration={500}
                            className='nav-list-items'
                        >
                            Projects
                        </Link>
                        <Link
                            activeClass="active"
                            to="contact"
                            offset={-70}
                            spy={true}
                            duration={500}
                            className='nav-list-items'
                        >
                            Contact
                        </Link>
                    </ul>
                </div>
            </div>
            <div onClick={openNav} className={`mobile-nav-list-container ${isNavOpen ? 'nav-show' : 'nav-hide'}`}>
                <ul className={`mobile-nav-list ${isNavBackground && 'mobile-nav-list-background'} ${isNavOpen ? 'nav-show' : 'nav-hide'}`}>
                    <Link
                        activeClass="active"
                        to="home"
                        spy={true}
                        duration={500}
                        className='mobile-nav-list-items'
                        onClick={openNav}
                    >
                        Home
                    </Link>
                    <Link
                        activeClass="active"
                        to="about"
                        offset={-100}
                        spy={true}
                        duration={500}
                        className='mobile-nav-list-items'
                        onClick={openNav}
                    >
                        About
                    </Link>
                    <Link
                        activeClass="active"
                        to="projects"
                        spy={true}
                        duration={500}
                        className='mobile-nav-list-items'
                        onClick={openNav}
                    >
                        Projects
                    </Link>
                    <Link
                        activeClass="active"
                        to="contact"
                        offset={-70}
                        spy={true}
                        duration={500}
                        className='mobile-nav-list-items'
                        onClick={openNav}
                    >
                        Contact
                    </Link>
                </ul>
                <div className='nav-menu-underline' />
            </div>
        </div >
    )
}