import React, { useState } from "react";
import axios from "axios";
import "./contact.css";

const validEmailRegex = RegExp(
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const Contact = () => {
	const [values, setValues] = useState({
		fullname: "",
		email: "",
		message: "",
	});
	const [isSent, setSent] = useState("");
	const [errors, setErrors] = useState({
		nameError: "",
		emailError: "",
		messageError: "",
	});
	const [succcess, setSucccess] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});

		switch (name) {
			case "fullname":
				setErrors({
					nameError:
						value.length < 5
							? "Name must be over 5 characters long!"
							: "",
				});
				break;
			case "email":
				setErrors({
					emailError: validEmailRegex.test(value)
						? ""
						: "Email is not valid!",
				});
				break;
			case "message":
				setErrors({
					messageError:
						value.length < 10
							? "Message must be over 10 characters long!"
							: "",
				});
				break;
			default:
				break;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (
			errors.emailError === "" ||
			errors.nameError === "" ||
			errors.messageError === ""
		) {
			axios
				// .post("/gridcontact", values)
				.post("/contact", values)
				.then(
					(res) => setSucccess(true),
					setValues({
						fullname: "",
						email: "",
						message: "",
					}),
					setSent("Sent!")
				)
				.catch(() => {
					console.log(`${succcess}: not posting`);
				});
		} else {
			setSent("Not sent!");
		}
	};

	return (
		<div id="contact" className="contact-container">
			<div className="contact-title">Contact</div>
			<div className="contact-divide-line"></div>
			<div
				style={
					isSent !== " "
						? { display: "block", color: "red" }
						: { display: "none" }
				}
			>
				{isSent}
			</div>
			<div className="contact-form">
				<form onSubmit={handleSubmit}>
					<span className="errors">{errors.nameError}</span>
					<label className="contact-form-label" htmlFor="fullname">
						Name
					</label>
					<input
						type="text"
						value={succcess ? " " : values.fullname}
						name="fullname"
						id="fullname"
						className="contact-form-input"
						onChange={handleInputChange}
					/>
					<span className="errors">{errors.emailError}</span>
					<label className="contact-form-label" htmlFor="email">
						E-mail
					</label>
					<input
						type="text"
						value={succcess ? "" : values.email}
						name="email"
						id="email"
						className="contact-form-input"
						onChange={handleInputChange}
					/>
					<span className="errors">{errors.messageError}</span>
					<label className="contact-form-label" htmlFor="message">
						Message
					</label>
					<textarea
						type="text-area"
						value={succcess ? "" : values.message}
						name="message"
						id="message"
						className="contact-form-input contact-form-message"
						onChange={handleInputChange}
					/>
					<input
						type="submit"
						title="Submit"
						className="contact-form-input contact-form-submit"
					/>
				</form>
			</div>
		</div>
	);
};

export default Contact;
