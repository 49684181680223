import React from "react";
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='footer-social-links-container' >
                <div className='footer-social-link' ><a href='https://www.linkedin.com/in/mihle-damoyi' target="_blank" rel='noreferrer'><FontAwesomeIcon icon={['fab', 'linkedin']} className='f-ic' size='lg' /></a></div>
                <div className='footer-social-link' ><a href="https://www.facebook.com/mihle.damoyi" target="_blank" rel='noreferrer'><FontAwesomeIcon icon={['fab', 'facebook']} className='f-ic' size='lg' /></a></div>
                <div className='footer-social-link' ><a href='https://github.com/GeminiWolf' target="_blank" rel='noreferrer'><FontAwesomeIcon icon={['fab', 'github']} className='f-ic' size='lg' /></a></div>
            </div>
            <div className='footer-owner-container' >
                <div>Mihle Damoyi &#169; <span style={{ color: "#3a4e48" }}>2020</span></div>
            </div>
        </div>
    )
}

export default Footer;